
























































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";

import FunnelStep from "@/funnels/components/FunnelStep.vue";
import FunnelModel, {
  FunnelStepModel,
  FunnelStepPeriodType,
  FunnelType,
  FUNNEL_STEP_NAME_MAX_LENGTH,
} from "@/funnels/models/FunnelModel";
import { FilterModel, ValidationRule } from "@/shared/models";

@Component({
  components: {
    FunnelStep,
    draggable,
  },
})
export default class FunnelSteps extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: FunnelModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: true }) stepsValid!: boolean;
  @Prop({ default: false }) autofocus!: boolean;

  @Ref() tabs!: Vue & { callSlider: () => void };

  readonly menuItemsForCreatingSteps = [
    {
      title: this.$lang("funnel.newStep"),
      value: "new",
    },
    {
      title: this.$lang("funnel.copyCurrentStep"),
      value: "copyCurrent",
    },
  ];
  newSteps: Array<FunnelStepModel> = [];
  currentTab = this.value.steps[0]
    ? `tab-0-${this.value.steps[0].key}`
    : "add_step_tab";
  copiedStep: FunnelStepModel | null = null;

  get backgroundColorForTabs() {
    return !this.$vuetify.theme.dark ? "grey lighten-4" : "";
  }

  get deleteButtonColor() {
    return (stepKey: string) => {
      return stepKey === this.currentTab ? "primary" : "gray";
    };
  }

  get sliderColor(): string {
    const currentTabKey = this.currentTab.split("-")[2];
    const found = this.value.steps.find(
      (step: FunnelStepModel) =>
        !this.isStepFilterValid(step.filter) && currentTabKey === step.key
    );

    return found &&
      !this.isNewStep() &&
      currentTabKey === found.key &&
      !this.stepsValid
      ? "error"
      : "primary";
  }

  @Watch("stepsValid")
  private watchStepsValid() {
    this.newSteps = [];
  }

  @Watch("currentTab", { immediate: true })
  private watchCurrentTab(currentTab: string) {
    if (currentTab === "copy_current_tab") {
      const newStep = new FunnelStepModel(
        this.value.steps.length + 1,
        this.copiedStep?.periodType,
        this.copiedStep?.periodValue,
        "",
        cloneDeep(this.copiedStep?.filter),
        cloneDeep(this.copiedStep?.excludeFilter),
        undefined,
        ""
      );
      this.value.steps.push(newStep);
      this.newSteps.push(newStep);
      this.currentTab = `tab-${this.value.steps.length - 1}-${
        this.value.steps[this.value.steps.length - 1].key
      }`;
    }

    if (currentTab !== "add_step_tab") {
      return;
    }

    const stepsCount = this.value.steps.length;
    const newStep =
      stepsCount > 0 && this.value.type === FunnelType.USER_CONVERSION
        ? new FunnelStepModel(stepsCount + 1, FunnelStepPeriodType.DAY, 7)
        : new FunnelStepModel(stepsCount + 1);
    this.value.steps.push(newStep);
    this.newSteps.push(newStep);

    this.currentTab = `tab-${this.value.steps.length - 1}-${
      this.value.steps[this.value.steps.length - 1].key
    }`;
  }

  @Watch("value.type")
  private watchFunnelType() {
    this.value.steps = [];
  }

  @Watch("value.steps")
  watchStepsCount() {
    this.tabs.callSlider();
  }

  isStepFilterValid(filters: Array<FilterModel>): boolean {
    return filters.every((filter: FilterModel) => filter.valid);
  }

  isNewStep(stepKey = ""): boolean {
    if (stepKey) {
      return !!this.newSteps.find(
        (step: FunnelStepModel) => stepKey === step.key
      );
    }

    const currentTabKey = this.currentTab.split("-")[2];

    return !!this.newSteps.find(
      (step: FunnelStepModel) => currentTabKey === step.key
    );
  }

  getColorForText(step: FunnelStepModel): string {
    return this.isStepUnValid(step) ? "error--text" : "";
  }

  isStepUnValid(step: FunnelStepModel): boolean {
    const isStepNameValid = step.name
      ? step.name.length <= FUNNEL_STEP_NAME_MAX_LENGTH
      : false;

    return (
      !(this.isStepFilterValid(step.filter) && isStepNameValid) &&
      !this.stepsValid &&
      !this.isNewStep(step.key)
    );
  }

  deleteStep(val: string) {
    const deleteIndex = this.value.steps.findIndex((step) => step.key === val);
    this.value.steps = this.value.steps
      .filter((_, index) => index !== deleteIndex)
      .map((step, index) => {
        step.number = index + 1;
        if (index === 0) {
          step.periodType = undefined;
          step.periodValue = undefined;
          step.excludeFilter = [];
        }
        return step;
      });

    if (deleteIndex < this.value.steps.length) {
      this.currentTab = `tab-${deleteIndex}-${this.value.steps[deleteIndex].key}`;
    } else if (deleteIndex > 0) {
      this.currentTab = `tab-${deleteIndex - 1}-${
        this.value.steps[deleteIndex - 1].key
      }`;
    }
  }

  updateTabsOrder(evt: any) {
    this.value.steps = this.value.steps.map((step, index) => {
      step.number = index + 1;
      if (index === 0) {
        step.periodType = undefined;
        step.periodValue = undefined;
        step.excludeFilter = [];
      }
      return step;
    });

    this.currentTab = `tab-${evt.newIndex}-${
      this.value.steps[evt.newIndex].key
    }`;
  }

  handleCreatingStep(value: string) {
    if (value === "copyCurrent") {
      this.copiedStep = this.value.steps[Number(this.currentTab.split("-")[1])];
      this.currentTab = "copy_current_tab";
    } else {
      this.currentTab = "add_step_tab";
    }
  }
}
