













import { Component, Prop, Vue } from "vue-property-decorator";

import Filters from "@/shared/components/filters/Filters.vue";
import {
  FilterModel,
  FilterId,
  FUNNEL_STEP_ADDITIONAL_FILTER_ID,
  ValidationRule,
} from "@/shared/models";

@Component({
  components: {
    Filters,
  },
})
export default class FunnelStepFilter extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: Array<FilterModel>;
  @Prop() type!: FilterId;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: false }) autofocus!: boolean;

  get items(): Array<FilterId> {
    if (this.type === FilterId.EVENT) {
      return FUNNEL_STEP_ADDITIONAL_FILTER_ID;
    }

    return [];
  }
}
