import { Vue, Component, Watch } from "vue-property-decorator";
import { Location, Route } from "vue-router";

import { UnsavedChangesRouting } from "@/shared/models";

@Component
export default class UnsavedChangesMixin extends Vue {
  isSavedForm = false;
  currentRoutePath = "";
  initialData = "";

  get hasUnsavedChanges(): boolean {
    return this.$store.state.unsavedChangesStore.hasUnsavedChanges;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  @Watch("applicationId")
  watchApplicationId() {
    const name = UnsavedChangesRouting.get(this.$route.name as string);

    if (!this.hasUnsavedChanges && name) {
      this.$router.push({ name });
    }
  }

  @Watch("isSavedForm")
  watchIsSavedForm(value: boolean) {
    if (value) {
      this.$store.commit("setUnsavedChanges", false);
    }
  }

  created() {
    this.currentRoutePath = this.$route.path;
  }

  beforeDestroy() {
    this.$store.commit("setUnsavedChanges", false);
  }

  handleWatchingUnsavedChanges() {
    this.$watch(
      "instanceForWatchingUnsavedChanges",
      function (newValue, oldValue) {
        const newValueJson = JSON.stringify(newValue);
        const oldValueJson = JSON.stringify(oldValue);

        if (!this.initialData) {
          this.initialData = oldValueJson;
        }

        if (
          this.initialData
            .replaceAll("\\n", "")
            .replace(/ /g, "")
            .replaceAll("\\", "") ===
          newValueJson
            .replaceAll("\\n", "")
            .replace(/ /g, "")
            .replaceAll("\\", "")
        ) {
          this.$store.commit("setUnsavedChanges", false);
          return;
        } else if (
          newValueJson
            .replaceAll("\\n", "")
            .replace(/ /g, "")
            .replaceAll("\\", "") !==
            oldValueJson
              .replaceAll("\\n", "")
              .replace(/ /g, "")
              .replaceAll("\\", "") &&
          !this.hasUnsavedChanges
        ) {
          this.$store.commit("setUnsavedChanges", true);
        }
      },
      {
        deep: true,
      }
    );
  }

  showUnsavedChangesDialog(nextRoute: Location | Route) {
    this.$store.commit("setUnsavedChangesDialogVisibility", true);
    this.$store.commit("setNextRoute", nextRoute);
  }
}
