



















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import FunnelStepFilter from "@/funnels/components/FunnelStepFilter.vue";
import {
  FunnelStepModel,
  FunnelStepPeriodType,
  FunnelType,
  FUNNEL_STEP_NAME_MAX_LENGTH,
} from "@/funnels/models/FunnelModel";
import { FilterId, ValidationRule } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    FunnelStepFilter,
  },
})
export default class FunnelStep extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: FunnelStepModel;
  @Prop() type!: FunnelType;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: false }) autofocus!: boolean;

  readonly nameRules = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.maxLength(
      FUNNEL_STEP_NAME_MAX_LENGTH,
      this.$lang("validation.maxLength", FUNNEL_STEP_NAME_MAX_LENGTH)
    ),
  ];
  filterType = FilterId.EVENT;
  excludeFilterType = FilterId.EVENT;
  exclude = !!this.value.excludeFilter.length;

  get showPeriod(): boolean {
    return (
      this.type === FunnelType.USER_CONVERSION &&
      this.value.number !== 1 &&
      (!this.readonly || (!!this.value.periodType && !!this.value.periodValue))
    );
  }

  get showExclude(): boolean {
    return (
      this.type === FunnelType.USER_CONVERSION &&
      this.value.number !== 1 &&
      (!this.readonly || !!this.value.excludeFilter.length)
    );
  }

  get periodTypes() {
    return Object.values(FunnelStepPeriodType).map((it) => {
      return {
        name: this.$lang(
          `funnel.step.periodType.${it.toString().toLowerCase()}`
        ),
        value: it,
      };
    });
  }

  get filterTypes() {
    return [FilterId.EVENT, FilterId.ACTIVITY_KIND].map((it) => {
      return {
        name: this.$lang(`shared.filters.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get periodValueRules() {
    return [
      ValidUtil.integer(this.$lang("validation.integer")),
      ValidUtil.min(0, this.$lang("validation.min", 0)),
    ];
  }

  get descriptionRules() {
    return [ValidUtil.maxLength(50, this.$lang("validation.maxLength", 50))];
  }

  @Watch("value", { immediate: true })
  private watchValue(value: FunnelStepModel) {
    this.filterType = value.filter.find(
      (it) => it.id === FilterId.ACTIVITY_KIND
    )
      ? FilterId.ACTIVITY_KIND
      : FilterId.EVENT;
    this.excludeFilterType = value.excludeFilter.find(
      (it) => it.id === FilterId.ACTIVITY_KIND
    )
      ? FilterId.ACTIVITY_KIND
      : FilterId.EVENT;
  }

  @Watch("filterType")
  private watchType() {
    this.value.filter = [];
  }

  @Watch("excludeFilterType")
  private watchExcludeType() {
    this.value.excludeFilter = [];
  }

  @Watch("exclude")
  private watchExclude() {
    this.value.excludeFilter = [];
  }

  onNameInput() {
    if (
      !this.value.name ||
      this.value.name.length <= FUNNEL_STEP_NAME_MAX_LENGTH
    ) {
      return;
    }

    this.value.name = this.value.name.substring(0, FUNNEL_STEP_NAME_MAX_LENGTH);
  }
}
