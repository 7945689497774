




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import FunnelModel, { FunnelType } from "@/funnels/models/FunnelModel";
import { AccessType, ValidationRule } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class FunnelInfo extends Vue {
  @Prop() value!: FunnelModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() rules?: Array<ValidationRule>;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get isFunnelNameUnique(): boolean {
    return this.$store.state.funnelStore.isFunnelNameUnique;
  }

  get types() {
    return Object.values(FunnelType).map((it) => {
      return {
        name: this.$lang(`funnel.type.${it.toLowerCase()}`),
        value: it,
      };
    });
  }

  get accessTypes(): Array<{ name: string; value: AccessType }> {
    return Object.values(AccessType).map((it) => {
      return {
        name: this.$lang(`accessType.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get nameErrorMessages(): Array<string> {
    if (!this.readonly && !this.isFunnelNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }
    if (this.value.name && this.value.name.length > 50) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }

    return [];
  }

  get descriptionErrorMessages(): Array<string> {
    if (this.value.description && this.value.description.length > 255) {
      return [this.$lang("shared.errors.maxCharacters", 255)];
    }

    return [];
  }

  get editable(): boolean {
    return this.value.editable ?? true;
  }

  @Watch("value.name", { immediate: true })
  private watchName() {
    if (!this.readonly && !this.isEdit) {
      this.$store.dispatch("checkIsFunnelNameUnique", this.value);
    }
  }
}
