




















































































import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";

import FunnelInfo from "@/funnels/components/FunnelInfo.vue";
import FunnelFilters from "@/funnels/components/FunnelFilters.vue";
import FunnelSteps from "@/funnels/components/FunnelSteps.vue";
import FunnelModel from "@/funnels/models/FunnelModel";
import {
  AttributionDateFilterModel,
  FilterId,
  MenuItems,
} from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    FunnelInfo,
    FunnelFilters,
    FunnelSteps,
  },
})
export default class NewFunnelView extends mixins(UnsavedChangesMixin) {
  @Prop() funnelId?: string;
  @Ref("stepForm") readonly stepForm!: Array<VueForm>;

  localFunnel: FunnelModel = new FunnelModel(this.applicationId, [
    new AttributionDateFilterModel(FilterId.ATTRIBUTION_DATE, true, true, true),
  ]);
  currentStep = 1;
  steps = [
    {
      name: this.$lang("funnel.tabs.info"),
      rules: [ValidUtil.required(this.$lang("validation.required"))],
      valid: true,
    },
    {
      name: this.$lang("funnel.tabs.filters"),
      rules: [ValidUtil.requiredArray(this.$lang("validation.required"))],
      valid: true,
    },
    {
      name: this.$lang("funnel.tabs.steps"),
      rules: [ValidUtil.requiredArray(this.$lang("validation.required"))],
      valid: true,
    },
  ];

  get previousButtonText(): string {
    return this.currentStep === 1
      ? this.$lang("btn.cancel")
      : this.$lang("btn.back");
  }

  get isDisabledForSteps(): boolean {
    return (
      !this.localFunnel.filter.find(
        (item) => item.id === FilterId.ATTRIBUTION_DATE
      )?.valid ||
      this.localFunnel.eventsFrom === "Invalid date" ||
      this.localFunnel.eventsTo === "Invalid date"
    );
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.localFunnel.steps.length < 2 ||
      !this.steps[this.currentStep - 1].valid
    );
  }

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return Object.assign({}, this.localFunnel);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  @Watch("funnelId", { immediate: true })
  private watchFunnelId(funnelId?: FunnelModel) {
    if (!funnelId) {
      return;
    }

    const copiedFunnel: FunnelModel =
      this.$store.state.funnelStore.funnels.find(
        (funnel: FunnelModel) =>
          funnel.id && funnel.id.toString() === this.funnelId
      );

    this.localFunnel = FunnelModel.ofSource(copiedFunnel, [
      FilterId.ATTRIBUTION_DATE,
    ]);
  }

  created() {
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  stepComplete(step: number) {
    return this.currentStep > step;
  }

  validate(stepIndex: number) {
    this.steps[stepIndex].valid = false;
    const isValid = this.stepForm[stepIndex].validate();

    if (isValid) {
      this.steps[stepIndex].valid = true;
      this.currentStep = stepIndex + 2;
    }
  }

  async handleSave() {
    const isValid: boolean = this.stepForm[this.currentStep - 1].validate();

    if (isValid) {
      await this.$store.dispatch("createFunnel", this.localFunnel);
      this.isSavedForm = true;
      this.$router.push({
        name: MenuItems.FUNNEL,
        params: { id: this.applicationId },
      });
    }
  }

  handlePrevious() {
    if (this.currentStep === 1) {
      this.$router.push({
        name: MenuItems.FUNNEL,
        params: { id: this.applicationId },
      });
    } else {
      this.currentStep = this.currentStep - 1;
    }
  }
}
