











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import FunnelModel from "@/funnels/models/FunnelModel";
import {
  FilterId,
  FUNNEL_FILTER_ID,
  FUNNEL_BREAKDOWN_FILTER_ID,
  ValidationRule,
  FilterModel,
} from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import Filters from "@/shared/components/filters/Filters.vue";
import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";

@Component({
  components: {
    Filters,
    DatesPicker,
  },
})
export default class FunnelFilters extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: FunnelModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() rules?: Array<ValidationRule>;

  localFilters: Array<FilterModel> = this.value.filter;

  get breakdowns() {
    return FUNNEL_BREAKDOWN_FILTER_ID.map((it) => {
      return {
        name: this.$lang(`shared.filters.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get showFilters(): boolean {
    return this.value.filter && this.value.filter.length > 0;
  }

  get filters(): Array<FilterId> {
    return FUNNEL_FILTER_ID;
  }

  get requiredFilters(): Array<FilterId> {
    return [FilterId.ATTRIBUTION_DATE];
  }

  get maxEventDate(): string {
    return DateUtil.addYears(this.value.eventsFrom, 1);
  }

  get minEventDate(): string {
    return DateUtil.subtractYears(this.value.eventsFrom, 1);
  }

  get localEventDates(): Array<string> {
    return [this.value.eventsFrom, this.value.eventsTo];
  }

  set localEventDates(value: Array<string>) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.eventsFrom = value[0];
    clonedLocalValue.eventsTo = value[1];
    this.$emit("input", clonedLocalValue);
  }

  @Watch("localFilters", { deep: true })
  watchLocalFilters(value: Array<FilterModel>) {
    const clonedLocalValue = cloneDeep(this.value);

    clonedLocalValue.filter = value;
    this.$emit("input", clonedLocalValue);
  }
}
